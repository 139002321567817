export function createReducer(ReducerClass) {
  return (state, paylaod) => {
    if (!state) {
      return new ReducerClass();
    }

    if (paylaod.type in ReducerClass) {
      return ReducerClass[paylaod.type](state, paylaod.data);
    }

    return state;
  };
}
