import { createReducer } from './create-reducer';

class User {
  /**
   *Creates an instance of User.
   * @param {{
   *  uid: string,
   *  name: string,
   *  email: string,
   *  sheetList: array
   * }} options
   * @memberof User
   */
  constructor(options = {}) {
    const { uid = '', name = '', email = '', sheetList = [] } = options;
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.sheetList = sheetList;
  }

  /**
   * Signin Action
   * @static
   * @param {User} state
   * @param {{
   *  uid: string,
   *  name: string,
   *  email: string,
   * }} payload
   * @returns User
   * @memberof User
   */
  static SIGNIN(state, payload = {}) {
    if ('uid' in payload) {
      const { uid, name, email } = payload;
      return new User({ uid, name, email });
    }

    return state;
  }

  /**
   * Signout Action
   * @static
   * @returns User
   * @memberof User
   */
  static SIGNOUT() {
    return new User();
  }

  /**
   * Update Sheet List Action
   * @static
   * @param {User} state
   * @param {{
   *  sheetList: array,
   * }} payload
   * @returns User
   * @memberof User
   */
  static UPDATE_SHEET_LIST(state, payload = {}) {
    const { sheetList } = payload;
    return new User({ ...state, sheetList });
  }
}

export const userReducer = createReducer(User);
