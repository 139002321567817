import { LitElement, html, css } from 'lit-element';
import { connect, installRouter, installOfflineWatcher } from 'pwa-helpers';
import '@polymer/app-layout/app-header/app-header';
import '@polymer/app-layout/app-toolbar/app-toolbar';
import '@polymer/app-layout/app-scroll-effects/app-scroll-effects';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-icon-button/paper-icon-button';
import '@polymer/iron-icon/iron-icon';
import '@polymer/iron-icons/iron-icons';
import '@polymer/paper-progress/paper-progress';

import { store } from './redux/store';
import { signIn, signOut, authChanged } from './snd-firebase/snd-firebase';
import './snd-home/snd-home';

class SheetsNDices extends connect(store)(LitElement) {
  static get properties() {
    return {
      route: {
        type: String,
        reflect: true,
        attribute: false,
      },
      offline: {
        type: Boolean,
        reflect: true,
      },
      authorized: {
        type: Boolean,
        reflect: true,
      },
      installable: {
        type: Boolean,
        reflect: true,
      },
      unresolved: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      /* :host */
      css`
        :host {
          display: grid;
          grid-template-rows: 64px auto;
          grid-template-columns: auto;
          grid-template-areas:
            'app-header'
            'app-body';
          min-height: 100vh;
          width: 100vw;
          contain: strict;
        }

        :host([offline]) #offline,
        :host([authorized]) #signout,
        :host([installable]) #install-app,
        :host([authorized]) #signin-progress,
        :host([unresolved]) #signin-progress {
          opacity: 1;
          visibility: visible;
        }

        :host([authorized]) #signin,
        :host([unresolved]) #signin {
          opacity: 0;
          visibility: hidden;
        }

        :host([authorized]) #app-signin-overlay {
          transform: translateY(100%);
        }
      `,
      /* header */
      css`
        app-toolbar > *:not(:last-child) {
          margin-right: 16px;
        }

        #app-header {
          grid-area: app-header;
          position: fixed;
          width: 100vw;
          color: var(--on-primary-color);
          background-color: var(--primary-color);
        }

        #signout,
        #offline,
        #install-app {
          opacity: 0;
          visibility: hidden;
          transition: opacity ease-in-out 0.33s;
          will-change: opacity, transition;
          contain: content;
        }
      `,
      /* signin */
      css`
        #app-signin-overlay {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 64px;
          left: 0;
          width: 100vw;
          height: calc(100vh - 64px);
          color: var(--on-primary-color);
          background-color: var(--primary-color);
          transition: transform ease-in-out 0.33s;
          will-change: transform, transition;
          contain: content;
        }

        #signin-progress {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          --paper-progress-active-color: var(--secondary-color);
        }

        #signin {
          opacity: 1;
          visibility: visible;
          transition: opacity ease-in-out 0.33s;
          will-change: opacity, transition;
          contain: content;
          color: var(--primary-color);
          background-color: var(--on-primary-color);
        }
      `,
      /* content */
      css`
        #app-body {
          grid-area: app-body;
        }
      `,
    ];
  }

  /* Lifecycle */
  constructor() {
    super();

    this.route = '';
    this.offline = false;
    this.authorized = false;
    this.unresolved = true;
    this.installable = false;
    this.installApp = null;

    authChanged(this._onAuthChanged.bind(this));
    installRouter(this._onRouteChanged.bind(this));
    installOfflineWatcher(this._onOfflineChanged.bind(this));
  }

  firstUpdated() {
    /* istanbul ignore next */
    window.addEventListener('beforeinstallprompt', evt => {
      this.installApp = evt;
      this.installable = !!evt;
    });
  }
  /* */

  /* Render */
  render() {
    return html`
      <app-header id="app-header" fixed effects="waterfall">
        <app-toolbar>
          <div main-title>Sheets 'n Dices</div>
          <paper-icon-button
            id="install-app"
            icon="add"
            @click="${this._installApp.bind(this)}"
          ></paper-icon-button>
          <iron-icon id="offline" icon="cloud-off"></iron-icon>
          <paper-icon-button
            id="signout"
            icon="power-settings-new"
            @click="${signOut}"
          ></paper-icon-button>
        </app-toolbar>
      </app-header>

      <section id="app-body">
        ${this._renderRoute()}
      </section>

      <section id="app-signin-overlay">
        <paper-progress id="signin-progress" indeterminate></paper-progress>
        <paper-button id="signin" raised @click="${signIn}">Sign In</paper-button>
      </section>
    `;
  }

  _renderRoute() {
    switch (this.route) {
      case '/create':
        return html`
          <div id="create">
            <span>Create</span>
            <a href="home">Home</a>
          </div>
        `;
      default:
        return html`
          <snd-home></snd-home>
        `;
    }
  }
  /* */

  /* Private */
  /* istanbul ignore next */
  _installApp() {
    this.installApp.prompt();
    this.installApp.userChoice.then(choiceResult => {
      this.installable = choiceResult.outcome !== 'accepted';
    });
  }

  _onOfflineChanged(offline) {
    this.offline = offline;
  }

  _onRouteChanged(location, event) {
    if (event && event.type === 'click') window.scrollTo(0, 0);
    this.route = location.pathname;
  }

  _onAuthChanged(auth) {
    this.unresolved = false;
    this.authorized = !!auth;

    if (this.authorized) {
      store.dispatch({
        type: 'SIGNIN',
        data: {
          uid: auth.uid,
          name: auth.displayName,
          email: auth.email,
        },
      });
    } else {
      store.dispatch({ type: 'SIGNOUT' });
    }
  }
  /* */

  /* Public */
  /* */
}

customElements.define('sheets-n-dices', SheetsNDices);
