import { combineReducers, createStore } from 'redux';
import { userReducer } from './user-reducer';
import { sheetReducer } from './sheet-reducer';

export const store = createStore(
  combineReducers({
    user: userReducer,
    sheet: sheetReducer,
  }),
);
