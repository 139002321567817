import { LitElement, html, css } from 'lit-element';
import { connect } from 'pwa-helpers';
import { getUserSheetList } from '../snd-firebase/snd-firebase';
import '@polymer/paper-fab/paper-fab';

import { store } from '../redux/store';

class SndHome extends connect(store)(LitElement) {
  static get properties() {
    return {
      sheetList: {
        type: Array,
        reflect: false,
        attribute: false,
      },
    };
  }

  static get styles() {
    return [
      /* :host */
      css`
        :host {
          display: block;
        }

        #add-sheet {
          position: fixed;
          bottom: 32px;
          right: 32px;

          --paper-fab-background: var(--secondary-color);
          --paper-fab-keyboard-focus-background: var(--dark-secondary-color);
        }

        #empty-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100vw;
          height: 64px;
          font-weight: bold;
          color: rgb(100, 100, 100);
        }
      `,
    ];
  }

  /* Lifecycle */
  constructor() {
    super();

    this.uid = '';
    this.sheetList = [];
  }

  stateChanged(state) {
    if (this.uid !== state.user.uid) {
      getUserSheetList(state.user.uid, this._onSheetListUpdate.bind(this));
    }

    this.uid = state.user.uid;
    this.sheetList = state.user.sheetList;
  }
  /* */

  /* Render */
  render() {
    return html`
      ${this.sheetList.length === 0
        ? html`
            <div id="empty-list">
              <span>You don't have any sheet</span>
            </div>
          `
        : this.sheetList.map(
            sheet =>
              html`
                <div>${JSON.stringify(sheet)}</div>
              `,
          )}

      <a href="/create">
        <paper-fab id="add-sheet" icon="add"></paper-fab>
      </a>
    `;
  }
  /* */

  /* Private */
  _onSheetListUpdate(sheetList) {
    store.dispatch({
      type: 'UPDATE_SHEET_LIST',
      data: { sheetList },
    });
    this.dispatchEvent(new CustomEvent('sheet-list-updated'));
  }
  /* */

  /* Public */
  /* */
}

customElements.define('snd-home', SndHome);
