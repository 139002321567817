import { createReducer } from './create-reducer';

class Sheet {
  /**
   *Creates an instance of Sheet.
   * @param {{
   *  id: string,
   *  name: string,
   *  abilities: array,
   *  resources: array,
   *  skills: array
   * }} options
   * @memberof Sheet
   */
  constructor(options = {}) {
    const { id = '', name = '', abilities = [], resources = [], skills = [] } = options;

    this.id = id;
    this.name = name;
    this.abilities = abilities;
    this.resources = resources;
    this.skills = skills;
  }

  /**
   * Load Sheet Action
   * @static
   * @param {Sheet} state
   * @param {{
   *  id: string,
   *  name: string,
   *  abilities: array,
   *  resources: array,
   *  skills: array
   * }} payload
   * @returns Sheet
   * @memberof Sheet
   */
  static LOAD_SHEET(state, payload = {}) {
    if ('id' in payload) {
      const { id, name, abilities, resources, skills } = payload;
      return new Sheet({ id, name, abilities, resources, skills });
    }

    return state;
  }

  /**
   * Unload Sheet Action
   * @static
   * @param {Sheet} state
   * @returns Sheet
   * @memberof Sheet
   */
  static UNLOAD_SHEET() {
    return new Sheet();
  }
}

export const sheetReducer = createReducer(Sheet);
